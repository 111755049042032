import React from 'react';
import { graphql } from 'gatsby';
import { SanityStateSpecificTerms, SanityTerms } from '../../graphql-types';
import { Layout } from '../components/Layout';
import TermsBlock from '../components/TermsPage/TermsBlock';
import { SEO } from '../components/SEO';
import { SolidDarkTheme } from '@parsleyhealth/cilantro-ui';
import { ThemeProvider } from '@parsleyhealth/cilantro-ui';

interface StateSpecificTermsProps {
  data: {
    sanityStateSpecificTerms: SanityStateSpecificTerms;
    sanityTerms: SanityTerms;
  };
}

export default function StateSpecificTerms({ data }: StateSpecificTermsProps) {
  const { sanityStateSpecificTerms, sanityTerms } = data;
  return (
    <ThemeProvider>
      <Layout transparent themeNav={SolidDarkTheme}>
        <SEO pageTitle={sanityStateSpecificTerms.title} />
        <TermsBlock firstInPage={true} {...sanityTerms} />
        <TermsBlock firstInPage={false} {...sanityStateSpecificTerms} />
      </Layout>
    </ThemeProvider>
  );
}

export const query = graphql`
  query StateSpecificTermsPageTemplateQuery($id: String!) {
    sanityStateSpecificTerms(id: { eq: $id }) {
      id
      title
      revisedAt(formatString: "MMMM DD, YYYY")
      _rawBody
      published
    }
    sanityTerms(slug: { current: { eq: "clinical-membership-terms" } }) {
      id
      title
      revisedAt(formatString: "MMMM DD, YYYY")
      _rawBody
    }
  }
`;
